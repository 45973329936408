import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as dayjs from 'dayjs';
import 'dayjs/locale/sv';
import * as relativeTime from 'dayjs/plugin/relativeTime';

import {
	adjustDateFormat,
	generateAmortizationPause,
	generateCounterOffer,
	generateDecreasePayment,
	generateExtraPayment,
	generateFinalPayment,
	generateIncreaseLoan,
	generateIncreasePayment,
	generateNewLoan,
	generateStandardPayment,
	OrganizationEventExtended,
} from 'app/helpers/events.helper';

import { OrganizationEventDto } from 'app/api';
import { EventName } from '../models/events.enum';
import { LoanFilterLoan } from '../shared/components/table-loan-filter/table-loan-filter.component';

dayjs.extend(relativeTime);

const FAILED_EVENTS = ['Failed', 'Declined', 'Canceled'];

@Injectable({
	providedIn: 'root',
})
export class EventsService {
	constructor(private translateService: TranslateService) {}

	checkIsFailedStatus = (event: OrganizationEventDto) => FAILED_EVENTS.includes(event.status);

	formatEventsForDisplay = (events: OrganizationEventDto[]): OrganizationEventExtended[] => {
		return (events || [])
			.map((event: OrganizationEventDto) => {
				return {
					...event,
					eventDateFormatted: adjustDateFormat(event.eventDate, this.translateService.currentLang),
					failedEvent: this.checkIsFailedStatus(event),
				};
			})
			.map((event: OrganizationEventDto): OrganizationEventExtended => {
				const eventType = this.eventTypeMapper[event.eventName];

				if (!eventType) {
					return null;
				}

				return eventType({ event, translateService: this.translateService });
			})
			.filter(event => event !== null); // in case unhandled event type is returned - prevent app rendering error
	};

	formatLSEventsForDisplay = (events: OrganizationEventDto[], loans: LoanFilterLoan[]): OrganizationEventExtended[] => {
		return this.formatEventsForDisplay(events).map(event => {
			const loan = loans.find(loan => loan.id === event.loanId);
			return {
				...event,
				fields: [...event.fields, { label: 'events.details.loan', value: loan ? loan.name : '' }],
			};
		});
	};

	eventTypeMapper = {
		[EventName.payment]: generateStandardPayment,
		[EventName.finalPayment]: generateFinalPayment,
		[EventName.extraPayment]: generateExtraPayment,
		[EventName.increaseLoan]: generateIncreaseLoan,
		[EventName.decreasePayment]: generateDecreasePayment,
		[EventName.increasePayment]: generateIncreasePayment,
		[EventName.amortizationPause]: generateAmortizationPause,
		[EventName.newLoan]: generateNewLoan,
		[EventName.counterOffer]: generateCounterOffer,
	};
}
