import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { EventsService } from '@services';
import { OrganizationEventDto } from 'app/api';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { OrganizationEventExtended } from '../../../helpers/events.helper';
import { LoansOverviewService } from '../../../services/loans-overview.service';
import { LoanFilterLoan } from '../table-loan-filter/table-loan-filter.component';

@Component({
	selector: 'app-events-list-ls',
	templateUrl: './events-list-ls.component.html',
})
export class EventsListLsComponent implements OnInit, OnChanges {
	@Input() events: OrganizationEventDto[];
	@Input() loading: boolean;
	@Input() canLoadMore: boolean;
	@Input() showLoanFilter = false;
	@Output() load = new EventEmitter<void>();

	selectedLoanIdFilter: string;
	availableLoans$: Observable<LoanFilterLoan[]>;
	lastAvailableLoans: LoanFilterLoan[] = [];

	mappedEvents: OrganizationEventExtended[] = [];

	constructor(
		private loansOverviewService: LoansOverviewService,
		private eventsService: EventsService,
		private translateService: TranslateService
	) {}

	ngOnInit(): void {
		this.availableLoans$ = this.loansOverviewService.getLoansOverview().pipe(
			map(loans => {
				return loans.map(loan => ({
					id: loan.loanId,
					name: loan.displayId,
				}));
			}),
			shareReplay(1)
		);
		// Combine loans (async) and events (static) for processing
		this.availableLoans$?.subscribe(loans => {
			this.updateMappedEvents(loans, this.events);
		});
	}

	ngOnChanges(changes: SimpleChanges) {
		this.translateService.onLangChange.subscribe(_ => {
			this.updateMappedEvents(this.lastAvailableLoans, this.events);
		});

		if (changes.events) {
			this.updateMappedEvents(this.lastAvailableLoans, this.events);
		}
	}

	private updateMappedEvents(loans: LoanFilterLoan[] = [], events: OrganizationEventDto[] = []): void {
		this.lastAvailableLoans = loans; // Cache the latest loans
		this.mappedEvents = this.eventsService.formatLSEventsForDisplay(events, loans);
	}

	get filteredEvents() {
		if (!this.selectedLoanIdFilter || this.selectedLoanIdFilter.trim() === 'all') {
			return this.mappedEvents;
		}

		return this.mappedEvents.filter(event => (event.loanId ? event.loanId.includes(this.selectedLoanIdFilter) : false));
	}

	public async handleLoanFilterChange(selectedLoanId: string) {
		this.selectedLoanIdFilter = selectedLoanId;
	}
}
