import { Component, Input } from '@angular/core';
import { SUPPORT_EMAIL_ADDRESS, SUPPORT_PHONE_NUMBER } from 'app/my-froda/my-froda.constants';

interface ContactLink {
	label: string;
	columnVersionLabel: string;
	icon: string;
	link: string;
	angularticsAction: string;
	angularticsLabel: string;
}

const CONTACT_LINKS: ContactLink[] = [
	{
		label: 'footer.inline-contact.email',
		columnVersionLabel: 'inline-contact.email',
		icon: 'envelope',
		link: 'mailto:' + SUPPORT_EMAIL_ADDRESS,
		angularticsAction: 'home.email',
		angularticsLabel: SUPPORT_EMAIL_ADDRESS,
	},
	{
		label: 'footer.inline-contact.phone',
		columnVersionLabel: 'inline-contact.phone',

		icon: 'phone',
		link: 'tel:' + SUPPORT_PHONE_NUMBER,
		angularticsAction: 'home.phone',
		angularticsLabel: SUPPORT_PHONE_NUMBER,
	},
];

@Component({
	selector: 'app-contact-links',
	templateUrl: './contact-links.component.html',
	styleUrls: ['./contact-links.component.scss'],
})
export class ContactLinksComponent {
	@Input() isColumnVersion = true;

	contactLinks = CONTACT_LINKS;
}
