<div class="mb-4">
	<h2 translate="counter-offer.your-loan.title" class="pt-6 pb-4"></h2>
	<ng-container *ngIf="isFixedLoan">
		<app-info-item key="counter-offer.your-loan.loan-amount">
			<app-badge type="alert">{{ 'counter-offer.updated' | translate }}</app-badge>
			<span class="ml-2">{{ newLoanAmount | money:0:'hideCurrency' }}</span>
		</app-info-item>
		<app-info-item *ngIf="hasPreviousFixedLoan" key="counter-offer.your-loan.total-loan">
			<span class="ml-2">{{ counterOfferWR.new_principal_due | money:0:'hideCurrency' }}</span>
		</app-info-item>
		<app-info-item key="counter-offer.your-loan.payback-time">{{ counterOfferWR.duration_in_months | months }}</app-info-item>
		<app-info-item key="counter-offer.your-loan.interest-rate">
			{{ counterOfferWR.price_point?.interestChangeFactor | percent:'1.0-2' }}
		</app-info-item>
		<app-info-item key="counter-offer.payment.standard-payment">
			{{ counterOfferWR.payment_schedule?.standard_payment | money:0 | interval: counterOfferWR.payment_interval?.type }}
		</app-info-item>
	</ng-container>
	<ng-container *ngIf="!isFixedLoan">
		<app-info-item key="counter-offer.your-loan.loan-amount">
			<app-badge type="alert">{{ 'counter-offer.updated' | translate }}</app-badge>
			<span class="ml-2">{{ newLoanAmount | money:0:'hideCurrency' }}</span>
		</app-info-item>
		<app-info-item *ngIf="hasPreviousFlexLoan" key="counter-offer.your-loan.total-loan">
			<span class="ml-2">{{ counterOfferWR.new_interest_due | money:0:'hideCurrency' }}</span>
		</app-info-item>
		<app-info-item key="counter-offer.your-loan.interest-rate">
			{{ counterOfferWR.price_point?.interestChangeFactor | percent:'1.0-2' }}
		</app-info-item>
		<app-info-item key="counter-offer.payment.standard-payment">
			{{ counterOfferWR.amortization_free_payment_used | money:0 | interval: counterOfferWR.payment_interval?.type }}
		</app-info-item>
	</ng-container>
</div>

<app-notification class="w-full" *ngIf="showErrorMessage" type="error">{{ 'counter-offer.decision.has-errors' | translate }}</app-notification>
<div class="flex gap-3 pt-4 pb-10">
	<app-button class="w-full" type="secondary" (action)="declineOfferModal = true" [disabled]="isBackofficeUser" [loading]="declineLoading">
		{{ 'counter-offer.button.decline' | translate }}
	</app-button>
	<app-button class="w-full" type="primary" (action)="approve()" [disabled]="isBackofficeUser || counterOfferWR.signatories?.length === 0" [loading]="approveLoading">
		{{ 'counter-offer.button.approve' | translate }}
	</app-button>
</div>

<app-decline-counter-offer-modal
	*ngIf="declineOfferModal"
	[declineLoading]="declineLoading"
	[visible]="declineOfferModal"
	(close)="declineOfferModal = false"
	(decline)="declineOffer($event)"></app-decline-counter-offer-modal>
