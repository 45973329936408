<div class="flex w-fit gap-4 items-center">
	<span
		*ngFor="let loan of loanList; let i = index"
		(click)="handleLoanClick(i)"
		class="cursor-pointer"
		[ngClass]="{'text-black underline': i === selectedLoanIndex,
							'text-black-60 hover:text-black ': i !== selectedLoanIndex}">
		{{ loan.name }}
	</span>
</div>
