<div class="flex flex-col w-full">
	<div class="flex md:items-center pt-6 px-3 justify-between w-full gap-4 md:gap-0 flex-col md:flex-row mb-2 md:mb-0">
		<h1 class="md:mb-2" translate="transactions.title"></h1>
	</div>
	<div *ngIf="transactions?.length > 0" class="flex flex-col flex-1 lg:px-3 min-h-128">
		<div class="hidden pb-4 md:flex lg:items-center lg:justify-between tabular-nums">
			<div class="flex-1 mb-4 overflow-y-auto">
				<ng-container *ngFor="let transaction of transactions; let i = index">
					<app-transaction-item [transaction]="transaction"></app-transaction-item>
				</ng-container>
			</div>
		</div>
		<div class="flex-1 w-full md:hidden">
			<div *ngFor="let transaction of transactions; let i = index" class="flex flex-col border-b border-light-grey">
				<div class="flex flex-col w-full p-4 cursor-pointer">
					<app-transaction-item [transaction]="transaction"></app-transaction-item>
				</div>
			</div>
		</div>
	</div>

	<div *ngIf="transactions?.length === 0 && !loading" class="flex-1 h-64 bg-white lg:rounded-lg lg:h-128 lg:shadow lg:border-none">
		<div class="flex flex-col items-center justify-center w-full h-full max-w-xs mx-auto text-center">
			<p class="mb-2 font-bold" translate="transactions.empty.title"></p>
			<p translate="transactions.empty.pre"></p>
		</div>
	</div>

	<div *ngIf="loading" class="flex-1 h-64 lg:h-128">
		<div class="flex flex-col items-center justify-center w-full h-full max-w-xs mx-auto text-center">
			<app-loader></app-loader>
		</div>
	</div>
</div>
