import { WebComponentWrapperOptions } from '@angular-architects/module-federation-tools';
import { Component, OnInit } from '@angular/core';
import { environment } from 'environments/environment';
@Component({
	selector: 'app-helix-component',
	templateUrl: './helix-component.component.html',
	styleUrls: ['./helix-component.component.scss'],
	//encapsulation: ViewEncapsulation.ShadowDom,
})
export class HelixComponentComponent implements OnInit {
	item: WebComponentWrapperOptions;
	props;
	events;

	ngOnInit(): void {
		this.item = {
			remoteEntry: `${environment.moduleFederation.selfServiceHost}/remoteEntry.js`,
			remoteName: 'whitelabel_selfservice',
			type: 'script',
			exposedModule: './web-components',
			elementName: 'wl-self-service-element',
		};

		this.props = {
			message: 'Hello from Shell',
		};

		this.events = {
			clicked: event => {
				console.debug('clicked!', event);
			},
		};
	}
}
