import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { EventsService } from '@services';
import { OrganizationEventDto } from 'app/api';
import { OrganizationEventExtended } from '../../../helpers/events.helper';
import { LoansOverviewService } from '../../../services/loans-overview.service';

@Component({
	selector: 'app-events-list',
	templateUrl: './events-list.component.html',
	styleUrls: ['./events-list.component.scss'],
})
export class EventsListComponent implements OnInit, OnChanges {
	@Input() events: OrganizationEventDto[];
	@Input() loading: boolean;
	@Input() canLoadMore: boolean;
	@Output() load = new EventEmitter<void>();

	mappedEvents: OrganizationEventExtended[] = [];

	constructor(
		private loansOverviewService: LoansOverviewService,
		private eventsService: EventsService,
		private translateService: TranslateService
	) {}

	ngOnInit(): void {}

	ngOnChanges(changes: SimpleChanges) {
		this.translateService.onLangChange.subscribe(_ => {
			this.mappedEvents = this.eventsService.formatEventsForDisplay(this.events);
		});

		if (changes.events) {
			this.mappedEvents = this.eventsService.formatEventsForDisplay(this.events);
		}
	}
}
