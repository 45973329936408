<!-- Hide column version on mobile since now the footer is always visible -->
<div [ngClass]="{'hidden lg:block': isColumnVersion}">
	<h2 *ngIf="isColumnVersion" class="my-2" [class.text-almost-black]="type === 'gray'" translate="inline-contact.title"></h2>
	<div class="flex flex-col" [ngClass]="{'lg:flex-row gap-4 lg:gap-20 items-center justify-center': !isColumnVersion}">
		<ng-container *ngFor="let item of contactLinks">
			<a
				href="{{item.link}}"
				class="flex items-center link"
				angularticsCategory="contact-action"
				angularticsAction="item.angularticsAction"
				angularticsLabel="item.angularticsLabel"
				angulartics2On="click">
				<ng-container *ngIf="!isColumnVersion">
					<div class="text-almost-black" [innerHtml]="item.label | translate | simpleMarkdown : { '*' : 'font-bold' }"></div>
				</ng-container>
				<div *ngIf="isColumnVersion" class="flex justify-between w-full max-w-sm py-3 border-b border-black-6">
					<div translate="{{item.columnVersionLabel}}"></div>
					<app-svg-icon class="ml-2 text-dark-grey" width="20" height="20" [iconName]="item.icon + '.svg'" [iconId]="item.icon"></app-svg-icon>
				</div>
			</a>
		</ng-container>
	</div>

	<!-- TODO: remove? -->
	<!-- Since it's hidden I'll coment it out for now. -->
	<!-- <a class="hidden mb-2 link">
		<app-svg-icon class="mr-2" width="20" height="20" [iconName]="'chat.svg'" [iconId]="'chat'"></app-svg-icon>
		Starta en chatt med kundtjänst
	</a> -->
</div>
