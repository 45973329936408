<div *ngIf="contracts?.length > 0" class="flex flex-col w-full lg:gap-2">
	<div *ngFor="let contract of contracts; let i = index" class="flex items-center justify-between px-4 py-3 bg-white lg:even:bg-white lg:rounded-2xl even:bg-almost-white">
		<div class="flex flex-col flex-1 sm:hidden">
			<p class="text-sm text-dark-grey">{{ contract.date | localDate }}</p>
			<p class="text-black">{{ getContractTitle(contract) }}</p>
		</div>
		<div class="flex-col flex-1 hidden sm:flex">
			<p class="text-sm text-dark-grey" translate="contracts.date"></p>
			<p class="text-black">{{ contract.date | localDate }}</p>
		</div>
		<div class="flex-col flex-1 hidden sm:flex">
			<p class="text-sm text-dark-grey" translate="contracts.type"></p>
			<p class="text-black">{{ getContractTitle(contract) }}</p>
		</div>
		<div class="flex flex-col sm:flex-1">
			<p *ngIf="contract.loanId" class="text-sm text-dark-grey" translate="contracts.id"></p>
			<p *ngIf="contract.loanId" class="text-black">{{ contract.loanId }}</p>
		</div>
		<div class="ml-4">
			<app-button *ngIf="!isContractLoading(contract.id)" [type]="'small'" [iconId]="'download'" (action)="getContract(contract.id)">PDF</app-button>

			<app-loader *ngIf="isContractLoading(contract.id)" class="mr-2"></app-loader>
		</div>
	</div>
	<div *ngFor="let agreement of agreements; let i = index" class="flex items-center justify-between px-4 py-3 bg-white lg:even:bg-white lg:rounded-2xl even:bg-almost-white">
		<div class="flex-col flex-1 hidden sm:flex">
			<p class="text-sm text-dark-grey" translate="contracts.term"></p>
			<p class="text-black">{{ agreement.name | translate }}</p>
		</div>
		<div class="ml-4">
			<app-button [type]="'small'" [iconId]="'download'" (action)="openAgreement(agreement.link)">PDF</app-button>
		</div>
	</div>
</div>
