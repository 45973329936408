<div class="flex justify-center w-full">
	<div [ngClass]="widthClass" class="flex-1 bg-dark rounded-2xl md:shadow-md">
		<div class="relative flex flex-col w-full md:flex-row">
			<div class="flex flex-col px-4 py-8 text-center md:px-8 md:w-1/3 md:justify-center">
				<p class="flex justify-center text-white/[.6]">{{ 'pending-card.title' | translate }}</p>
				<h1 class="text-5xl font-bold text-center text-brand">
					{{ pendingRequest?.new_principal_due.amount - pendingRequest?.current_principal_due.amount | asMoney : currency : 0 }}
				</h1>
				<div *ngIf="externalContractStatusUpdating === false" class="py-4">
					<ng-container *ngIf="!shouldSignContractAfterApproval">
						<p class="text-center text-white" *ngIf="awaitingContractsCreated">{{ 'pending-card.awaiting-contracts.pre' | translate }}</p>
						<p class="text-center text-white" *ngIf="awaitingApprovalAndSignature">{{ 'pending-card.awaiting-approval-and-signature.pre' | translate }}</p>
						<p class="text-center text-white" *ngIf="awaitingSignature">{{ 'pending-card.awaiting-signature.pre' | translate }}</p>
						<p class="text-center text-white" *ngIf="awaitingApproval">{{ 'pending-card.awaiting-approval.pre' | translate }}</p>
					</ng-container>
					<ng-container *ngIf="shouldSignContractAfterApproval">
						<p class="text-center text-white" *ngIf="awaitingApprovalBeforeCreatingContracts">{{ 'pending-card.awaiting-approval.pre' | translate }}</p>
						<p class="text-center text-white" *ngIf="awaitingSignature">{{ 'pending-card.awaiting-signature.pre' | translate }}</p>
					</ng-container>
				</div>
				<ng-container *ngIf="externalContractStatusUpdating">
					<div class="flex flex-col items-center justify-center w-full h-full mb-4">
						<app-loader></app-loader>
					</div>
				</ng-container>
				<ng-container *ngIf="showContractsTimeoutError$ | async">
					<div class="w-full p-4 bg-white rounded-notification">
						<h2 class="text-base" translate="pending-card.contract-timeout.title"></h2>
						<p class="text-sm" translate="pending-card.contract-timeout.pre"></p>
					</div>
				</ng-container>
				<ng-container *ngIf="!(showContractsTimeoutError$ | async) && externalContractStatusUpdating === false">
					<div *ngIf="awaitingContractsCreated && !shouldSignContractAfterApproval" class="flex flex-col items-center justify-center w-full h-full mb-4">
						<app-loader></app-loader>
					</div>
					<div class="px-4 pb-4" *ngIf="usesSignicatSigning && !contractsSigned">
						<app-button
							type="outline"
							(action)="openContractLink()"
							[loading]="openingContract || !(contractSigningUrlReady$ | async)"
							[disabled]="awaitingContractsCreated || !(contractSigningUrlReady$ | async) || isBackOfficeUser">
							{{ 'pending-card.sign-button.text' | translate }}
						</app-button>
					</div>
					<app-signatory-list
						*ngIf="!usesSignicatSigning && !contractsSigned"
						[pendingRequestId]="pendingRequest?.id"
						[signatories]="pendingRequest?.signatories"
						[signButtonType]="signButtonType"
						[bgColor]="'bg-transparent'"
						[whiteLinkColor]="true"
						[titleTextColor]="'text-white'"
						[subTextColor]="'text-white/[.6]'"></app-signatory-list>
				</ng-container>
				<div class="flex justify-center w-full">
					<app-button
						class="max-w-fit md:hidden"
						(action)="collapsed = !collapsed"
						[type]="'small'"
						[darkMode]="true"
						[iconId]="collapsed ? 'chevron-down' : 'close'"
						[contentPosition]="'left'">
						{{ collapsed ? ('pending-card.read-more' | translate) : ('pending-card.close' | translate) }}
					</app-button>
				</div>
			</div>

			<div *ngIf="!collapsed" class="flex flex-col items-stretch mx-4 mb-4 text-center bg-white rounded-lg md:my-4 md:mr-4 md:ml-0 md:text-left text-almost-black md:w-2/3">
				<app-application-summary
					[pendingRequest]="pendingRequest"
					[paymentType]="paymentType"
					[currency]="currency"
					[canCancelApplication]="canCancelApplication"
					[contractsSigned]="contractsSigned"
					[isCardBasedLending]="isCardBasedLending"
					(cancelApplication)="cancelApplication.emit()"></app-application-summary>
			</div>
		</div>
	</div>
</div>
