import { Component, Input } from '@angular/core';
import { FixedLoanPriceResponseDto, PaymentIntervalType } from 'app/api';

@Component({
	selector: 'app-new-loan-summary',
	templateUrl: './new-loan-summary.component.html',
	styleUrl: './new-loan-summary.component.scss',
})
export class NewLoanSummaryComponent {
	@Input() offer: FixedLoanPriceResponseDto;
	@Input() offerStale: boolean;
	@Input() paymentIntervalType: PaymentIntervalType;
	@Input() hasPreviousLoan: boolean;
}
