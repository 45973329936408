import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SharedFileHeaderDto } from 'app/api';

@Component({
	selector: 'home-reports',
	templateUrl: './home-reports.component.html',
	styleUrls: ['./home-reports.component.scss'],
})
export class HomeReportsComponent implements OnInit {
	@Input() reports: SharedFileHeaderDto[];
	@Input() fixedHeight = false;
	@Output() clickedMore = new EventEmitter<void>();
	@Output() clickedReport = new EventEmitter<string>();
	partner: string;
	@Input() hideReports = false;
	@Input() reportsCount = 5;

	constructor(private route: ActivatedRoute) {}

	ngOnInit(): void {
		this.route.parent.paramMap.subscribe(params => {
			this.partner = params.get('partner');
		});
	}
}
