import { WebComponentWrapper, WebComponentWrapperOptions } from '@angular-architects/module-federation-tools';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { environment } from 'environments/environment';
import { MaintenanceViewComponent } from './maintenance-view/maintenance-view.component';
import { MaintenanceGuard } from './shared/guards/maintenance.guard';
import { UnavailableGuard } from './shared/guards/unavailable.guard';
import { TailwindLibraryComponent } from './tailwind-library/tailwind-library.component';
import { UnavailablePageComponent } from './unavailable-page/unavailable-page.component';

export const routedComponents = [TailwindLibraryComponent, MaintenanceViewComponent];

const routes: Routes = [
	{ path: '', pathMatch: 'full', redirectTo: '/login' },
	{ path: 'signup/partner-landing', redirectTo: '/partner/landing/default' },
	{ path: 'signup/:partner', redirectTo: '/partner/signup/:partner' },
	{
		path: 'getstarted',
		loadChildren: () => import('./signup/signup.module').then(m => m.SignupModule),
		canActivate: [MaintenanceGuard],
	},
	{ path: 'home', redirectTo: '/v2/home' },
	{
		path: 'partner',
		loadChildren: () => import('./partner/partner.module').then(m => m.PartnerModule),
		canActivate: [MaintenanceGuard],
	},
	{
		path: 'p/:partner',
		loadChildren: () => import('./white-label-client/white-label-client.module').then(m => m.WhiteLabelClientModule),
		canActivate: [MaintenanceGuard, UnavailableGuard],
	},
	{
		path: 'apply',
		loadChildren: () => import('./onboarding/onboarding.module').then(m => m.OnboardingModule),
		canActivate: [MaintenanceGuard, UnavailableGuard],
	},
	{
		path: 'partner-portal',
		loadChildren: () => import('./partner-portal/partner-portal.module').then(m => m.PartnerPortalModule),
		canActivate: [MaintenanceGuard, UnavailableGuard],
	},
	{
		path: 'login',
		loadChildren: () => import('./entry/entry.module').then(m => m.EntryModule),
		canActivate: [MaintenanceGuard, UnavailableGuard],
	},
	{
		path: 'v2',
		loadChildren: () => import('./my-froda/my-froda.module').then(m => m.MyFrodaModule),
		canActivate: [MaintenanceGuard, UnavailableGuard],
	},
	{ path: 'tailwind', component: TailwindLibraryComponent },
	{
		path: 'start',
		children: [
			{ path: 'new-customer', redirectTo: '/apply' },
			{ path: 'ref/korkortsjakten', redirectTo: '/apply/partner/korkortsjakten' },
			{ path: 'ref/bokadirekt', redirectTo: '/apply/partner/bokadirekt' },
			{ path: 'existing-customer', redirectTo: '/getstarted' },
		],
	},
	{
		path: 'helix',
		children: [
			{
				path: '**',
				component: WebComponentWrapper,
				data: {
					remoteEntry: `${environment.moduleFederation.selfServiceHost}/remoteEntry.js`,
					remoteName: 'whitelabel_selfservice',
					type: 'script',
					exposedModule: './web-components',
					elementName: 'wl-self-service-element',
				} as WebComponentWrapperOptions,
			},
		],
	},
	{
		path: '',
		loadChildren: () => import('./contract/contract.module').then(m => m.ContractModule),
		canActivate: [MaintenanceGuard, UnavailableGuard],
	},
	{
		path: 'contract',
		loadChildren: () => import('./contract/contract.module').then(m => m.ContractModule),
		canActivate: [MaintenanceGuard, UnavailableGuard],
	},
	{
		path: 'maintenance',
		component: MaintenanceViewComponent,
	},
	{
		path: 'unavailable',
		component: UnavailablePageComponent,
	},
	{
		path: '**',
		redirectTo: '/login',
	},
];

@NgModule({
	imports: [RouterModule.forRoot(routes, { enableTracing: environment.enableTracing })],
	exports: [RouterModule],
})
export class AppRoutingModule {}
