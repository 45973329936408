import { Injectable } from '@angular/core';
import { LoanOverviewDto, LoansHttpService, LoansSummaryDto } from 'app/api';
import { Observable } from 'rxjs';
import { shareReplay } from 'rxjs/operators';
import { OrganizationService } from './organization.service';

@Injectable({
	providedIn: 'root',
})
export class LoansOverviewService {
	private loansOverview$: Observable<LoanOverviewDto[]> | null = null;
	private lastUsedOrgId: string | null = null;

	constructor(private loansHttpService: LoansHttpService, private organizationService: OrganizationService) {}

	getLoansSummary(): Observable<LoansSummaryDto> {
		const id = this.organizationService.getOrganizationId();
		const creditId = this.organizationService.getCreditId();
		return this.loansHttpService.getLoansSummary(id, creditId);
	}

	getLoansOverview(): Observable<LoanOverviewDto[]> {
		if (!this.loansOverview$) {
			const id = this.organizationService.getOrganizationId();
			const creditId = this.organizationService.getCreditId();

			this.lastUsedOrgId = id;

			this.loansOverview$ = this.loansHttpService.getLoansOverview(id, creditId).pipe(
				shareReplay(1) // Cache the result and share it among subscribers
			);
		}
		if (this.lastUsedOrgId !== this.organizationService.getOrganizationId()) {
			this.loansOverview$ = null;
			return this.getLoansOverview();
		}

		return this.loansOverview$;
	}

	// This method should be used to refresh the loans overview
	// Don't know if or when it will be used but leaving it here in case it's needed
	refreshLoansOverview(): void {
		this.loansOverview$ = null;
	}
}
