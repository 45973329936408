<div class="flex flex-col gap-4 mb-10">
	<h2 translate="new-loan-summary.title"></h2>
	<div class="flex justify-between">
		<p translate="new-loan-summary.interest"></p>
		<p *ngIf="offerStale === false; else loadingTemplate;" class="text-black">{{ (offer?.price_point.interestPercentage | percent:'1.0-2' || 0) }}</p>
	</div>
	<div class="flex justify-between">
		<p translate="new-loan-summary.average-monthly-interest"></p>
		<p *ngIf="offerStale === false; else loadingTemplate;" class="text-black">{{ (offer?.total_interest_per_month | money:0) }}</p>
	</div>
	<div class="flex justify-between">
		<p translate="new-loan-summary.average-monthly-amortisation"></p>
		<p *ngIf="offerStale === false; else loadingTemplate;" class="text-black">{{ (offer?.total_amortization_per_month | money:0) }}</p>
	</div>
	<div class="flex justify-between">
		<p class="font-bold text-black" translate="new-loan-summary.average-monthly-payment"></p>
		<p *ngIf="offerStale === false; else loadingTemplate;" class="font-bold text-black">{{ (offer?.total_cost_per_month | money:0) }}</p>
	</div>
</div>

<app-infomercial-video></app-infomercial-video>

<div class="flex flex-col gap-4 mb-10">
	<h2 translate="new-loan-summary.payment-plan.title"></h2>
	<div class="flex justify-between">
		<p translate="new-loan-summary.payment-plan.payment"></p>
		<p *ngIf="offerStale === false; else loadingTemplate;" class="text-black">{{ offer?.standard_payment | money:0 | interval: paymentIntervalType }}</p>
	</div>
	<div *ngIf="hasPreviousLoan" class="flex justify-between">
		<p translate="new-loan-summary.payment-plan.payments"></p>
		<p *ngIf="offerStale === false; else loadingTemplate;" class="text-black"></p>
	</div>
</div>

<ng-template #loadingTemplate>
	<div class="w-1/3 text-right animate-pulse">
		<div class="w-full h-6 bg-medium-grey"></div>
	</div>
</ng-template>
